import { LoginProviderRecord } from 'services/login-provider/login-provider.interface';
import { LoginProviderState } from 'services/login-provider/+state/login-provider.state';
import { ExternalLoginSettings } from 'interfaces/external-login.interface';
import { SIGN_IN_TYPES } from 'pages/entry/entry.helpers';

export const DEFAULT_LOGIN_PROVIDER: LoginProviderRecord[] = [
  {
    provider: SIGN_IN_TYPES.ONE_LOGIN,
    enabled: false,
    loginProviderName: 'onelogin',
  },
  {
    provider: SIGN_IN_TYPES.OKTA,
    enabled: false,
    loginProviderName: 'okta',
  },
  {
    provider: SIGN_IN_TYPES.AZURE,
    enabled: false,
    loginProviderName: 'azure',
  },
  {
    provider: SIGN_IN_TYPES.PING,
    enabled: false,
    loginProviderName: 'ping'
  },
];

export function mapLoginProviderRecordToSettings(response: LoginProviderState): ExternalLoginSettings {
  const values = Object.values(SIGN_IN_TYPES);
  const { organizationId, loginProviders } = response;
  return loginProviders.reduce(
    (res, { provider, enabled, loginProviderName }: LoginProviderRecord) => {
      if (values.includes(provider)) {
        res[provider] = {
          enabled,
          loginProviderName,
        };
      }
      return res;
    },
    { organizationId, page: null },
  );
}
